import { Layout, SEO, Title } from "../components"

import { Grid } from "semantic-ui-react"
import React from "react"
import styled from "styled-components"

const ContactPage = () => (
  <Layout>
    <Wrapper>
      <SEO title="Contact" />
      <Title title="Contact" />
      <Grid centered>
        <Grid.Column width={8}>
          <form
            method="post"
            className="ui form"
            name="contact"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <div className="field required">
              <label htmlFor="name">Nom</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field required">
              <label htmlFor="email">Email</label>
              <input type="email" name="email" id="email" />
            </div>
            <div className="field required">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="5" />
            </div>

            <button type="submit" className="ui button">
              Envoyer
            </button>
            <input type="reset" value="Réinitialiser" className="ui button" />
          </form>
        </Grid.Column>
      </Grid>
    </Wrapper>
  </Layout>
)

const Wrapper = styled.main`
  background: var(--clr-grey-9);
  min-height: calc(100vh - 4rem);
  label {
    text-align: left;
  }

  h2 {
    font-size: 2rem;
    color: var(--clr-grey-1);
    margin: 2rem 0;
  }
  @media (min-width: 800px) {
    h2 {
      font-size: 3rem;
    }
  }
`

export default ContactPage
